import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import logo from './img/ProfilePic.jpg';
//import './App.css';
import './styles/navbar.css';
//import Sidebar from './pages/components/sidebar.js';
import Samples from './pages/samples.js';
import Feedbacks from './pages/feedback.js';
import Message from './pages/message.js';
import Overview from './pages/overview.js';
import Experience from './pages/experience.js';
import Technical_illustrations from './pages/technical_illustration.js'
import Screenshots from "./pages/screenshots.js";
import Certifications from "./pages/certifications.js";


const Title = ({data}) => (
  <h3>{data.title}</h3>
);

function App() {
  const [style, setStyle] = useState();
  const [btnPrs, setPress] = useState(false);
  const [active_tab, setActivetab] = useState('0')
  const [jsonData, setJsonData] = useState([]);

  const PressButton = () => {
    setPress(!btnPrs)
  }


  const changeStyle = (e) => {
    document.getElementById(active_tab).classList.remove("clickedEffect")
    document.getElementById(active_tab).children[0].style.color= ""
    console.log(document.getElementById(active_tab).children[0].style.color)
    setActivetab(e.currentTarget.id)
    if (style !== "clickedEffect")  {
      e.currentTarget.classList.toggle("clickedEffect");
      e.currentTarget.children[0].style.color= "white";
    }
    else {
      setStyle("");
    }
    btnPress();
  };

  useEffect(() => {
    btnPress();
    var loc = window.location.href;
    var loc = loc.substring(loc.lastIndexOf('/'), loc.length);
    if ( loc == "/experiences") markNav('1');
    else if ( loc == "/feedbacks") markNav('2');
    else if ( loc == "/samples") markNav('3');
    else if ( loc == "/technical-illustrations") markNav('4');
    else if ( loc == "/Certifications") markNav('5');
    else if ( loc == "/Screenshots-&-Proofs") markNav('6');
    else if ( loc == "/message") markNav('7');
    else markNav('0');
  },[]);

  useEffect(() => {
    fetch('./json/overview.json').then(response => {
        return response.json();
      }).then(data => {
        setJsonData(data)
        console.log(JSON.stringify(jsonData))
      }).catch((e) => {
        console.log(e.message);
      });
  },[])

  function markNav(id){
    // Marking new tab
    setActivetab(id)
    document.getElementById(id).classList.toggle("clickedEffect");
    document.getElementById(id).children[0].style.color= "white"
  }

  const btnPress = () => {
    PressButton()
    if(btnPrs){
      document.getElementById("sidemenu").style.display = "block";
      document.getElementById("sidemenu").classList.toggle("responsiveMenu");
      document.getElementById("content").classList.toggle("responsiveContent");
    }
    else {
      document.getElementById("sidemenu").style.display = "none";
      document.getElementById("content").classList.remove("responsiveContent");
    }
  }
  return (
    <Router>
    <div class="wrapper">
    <button class="btn" onClick={btnPress}><svg className="svg"
        viewBox="0 0 28 25"
        xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#fff" fillRule="evenodd">
        <rect width="28" height="3"/>
        <rect width="28" height="3" y="11"/>
        <rect width="28" height="3" y="22"/>
      </g>
    </svg></button>
    <div class="sidebar" id="sidemenu">
        <div class="logo">
            <img src={logo}/>
            {
              jsonData.map((dataItem) => (
                  <Title key={dataItem.id} data={dataItem} />
              ))
            }
        </div>
        <ul>
            <li id='0' className={style} onClick={changeStyle}><NavLink to="/">Overview</NavLink></li>
            <li id='1' className={style} onClick={changeStyle}><NavLink to="/experiences">Experience</NavLink></li>
            <li id='2' className={style} onClick={changeStyle}><NavLink to="/feedbacks">Feedbacks</NavLink></li>
            <li id='3' className={style} onClick={changeStyle}><NavLink to="/samples">Samples</NavLink></li>
            <li id='4' className={style} onClick={changeStyle}><NavLink to="/technical-illustrations">Technical Illustrations</NavLink></li>
            <li id='5' className={style} onClick={changeStyle}><NavLink to="/Certifications">Certifications</NavLink></li>
            <li id='6' className={style} onClick={changeStyle}><NavLink to="/Screenshots-&-Proofs">Screenshots & Proofs</NavLink></li>
            <li id='7' className={style} onClick={changeStyle}><NavLink to="/message">Message Me!</NavLink></li>
        </ul> 
        <div class="social_media">
            <NavLink to="#"><i className="fab fa-facebook-f"></i></NavLink>
            <NavLink to="#"><i className="fab fa-twitter"></i></NavLink>
            <NavLink to="#"><i className="fab fa-instagram"></i></NavLink>
        </div>
    </div>
    <div class="main_content" id="content">
     <div class="info">
          <Routes>
           <Route path='/' element={<Overview />} />
           <Route path='/experiences' element={<Experience />}/>
           <Route path='/feedbacks' element={<Feedbacks />} />
           <Route path='/samples' element={<Samples />} />
           <Route path='/Certifications' element={<Certifications />} />
           <Route path='/Screenshots-&-Proofs' element={<Screenshots />} />
           <Route path='/technical-illustrations' element={<Technical_illustrations />} />
           <Route path='/message' element={<Message />} />
          </Routes> 
      </div>
      <div>
      </div>
    </div>
    </div>
  </Router>
  );
}

export default App;
