import React, { useState, useEffect } from "react";
import '../styles/sample.css'

const Sample = ({ data }) => (
    <div className="samples">
        <p>{data.title} <em>{data.cata}</em></p>
        <a href={data.link} target="_blank">View</a>
    </div> 
);

const Samples = () =>{
    const [jsonData, setJsonData] = useState([]);

    useEffect(() => {
        fetch('./json/samples.json').then(response => {
            return response.json();
          }).then(data => {
            setJsonData(data)
          }).catch((e) => {
            console.log(e.message);
          });
      },[])

    return(
    <>
        <h1 className="sampleHeader">Samples</h1>
        {
            jsonData.map((dataItem) => (
                <Sample key={dataItem.id} data={dataItem} />
              ))
        }

     </>
    )
}

export default Samples;