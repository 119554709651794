import React, { useState, useEffect } from "react";
import '../styles/feedback.css'

const Feeds = ({data}) => (
  <div className="feed">
    <div className="client">
        <h2>{data.name}</h2>
        <h3>{data.position}</h3>
      </div>
      <div className="feedback">
      <p>"{data.feedback}"</p>
      </div>
  </div>
);

const Feedbacks = () =>{
  const [jsonData, setJsonData] = useState([]);

  useEffect(() => {
      fetch('./json/feedback.json').then(response => {
          return response.json();
        }).then(data => {
          setJsonData(data)
        }).catch((e) => {
          console.log(e.message);
        });
    },[])

    return(
        <>
        <h1 className="feeHeader">Feedbacks</h1>
        {
            jsonData.map((dataItem) => (
                <Feeds key={dataItem.id} data={dataItem} />
              ))
        }
        </>
    )
}

export default Feedbacks;