import React, { useState, useEffect } from "react";
import '../styles/experiences.css'

const Jobs = ({data}) => (
    <div className="experiences">
    <div className="job">
        <h2>{data.title}</h2>
        <h3>{data.company}</h3>
    </div>
    <div className="duration">
        <p><em>Duration</em><br></br><b>{data.duration}</b></p>
    </div>
    <div className="type">
        <p><em>Contract Type</em><br></br><b>{data.type}</b></p>
    </div> 
 </div>  
);

const Experience = () => {
    const [jsonData, setJsonData] = useState([]);

    useEffect(() => {
        fetch('./json/experience.json').then(response => {
            return response.json();
          }).then(data => {
            setJsonData(data)
          }).catch((e) => {
            console.log(e.message);
          });
      },[])

    return(
        <>
          <h1 className="experienceHeader">Experiences</h1>
          {
            jsonData.map((dataItem) => (
                <Jobs key={dataItem.id} data={dataItem} />
              ))
          }
        </>

    )
}

export default Experience;