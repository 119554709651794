import React, { useState } from "react";
import axios from "axios";
import Popup from './components/popup.js';
import '../styles/message.css'

const PopUpMsg = () => {
    return(
        <>
            <b>Thanks for your message!</b>
            <p>Please be active in your email</p>
        </>
    );
}

const Message = () =>{
    const [isOpen, setIsOpen] = useState(false);
 
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }

    const formsubmit = () => {
        if (document.getElementById('email').value == "") 
           document.getElementById("msg").style.display = 'block';
        else {
            // Collecting data
            var a = document.getElementById("name").value;
            var b = document.getElementById("email").value;
            var c = document.getElementById("message").value;

            // Organizing data
            var data = a+"<br>"+b+"<br>"+c+"<br>";

            // Sending Data
            axios.get("https://triantasystems.com/js/message.php?q="+data)
            .then(response => {
              console.log(response.data);
            })
            .catch(error => {
              console.log(error);
            });

            // Confirming User
            togglePopup()

            // Reset
            document.getElementById("name").value = "";
            document.getElementById("email").value = "";
            document.getElementById("message").value = "";
            document.getElementById("msg").style.display = 'none'
        }
    }

    return(
        <>
           <h1 className="messageHeader">Message Me!</h1>
             <div className="message_fields">
                <div>
                    <label for="fname">Full Name: </label>
                    <input type="text" id="name" name="fname"/>
                </div>
                <div>
                    <label for="email">Email: </label>
                    <input type="email" id="email" required/> 
                    <p id="msg">Please enter your valid email address.</p>
                </div>
            </div>
            <div className="message_text_area">
                <textarea id="message" rows={10} cols={50} placeholder="Your Message here..."></textarea>
            </div>
            <div className="message_submit">
                <button onClick={formsubmit}>Send</button>
            </div>
          {isOpen && <Popup
            content={<PopUpMsg />}
                handleClose={togglePopup}
            />}
        </>
    )
}

export default Message;