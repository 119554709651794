import React, { useState, useEffect } from "react";
import '../styles/screenshots.css'
import Popup from './components/popup.js';
import '../styles/infograph.css'

const Screenshots = () => {
    const [jsonData, setJsonData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [link, setLink] = useState("");


    const togglePopup = () => {
      setIsOpen(!isOpen);
    }

    const Jobs = ({data}) => (
      <img src={data.link} onClick={getLink}/>
    );
    
    const getLink = (e) => {
      setLink(e.target.src)
      setIsOpen(!isOpen);
    }
    
    useEffect(() => {
        fetch('./json/screenshots.json').then(response => {
            return response.json();
          }).then(data => {
            setJsonData(data)
          }).catch((e) => {
            console.log(e.message);
          });
      },[])

    return (
        <>
        <h1 className="screenshotsHeader">Screenshots & Proofs (Upwork & Other Marketplaces)</h1>
        <div id="images-wrapper">
            {
            jsonData.map((dataItem) => (
                <Jobs key={dataItem.no} data={dataItem} />
            ))
            }
            {isOpen && <Popup
            content={
            <img src={link} width="800px"/>
            }
                handleClose={togglePopup}
            />}
        </div>
        </>
    )
}

export default Screenshots;