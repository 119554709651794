import React, { useState, useEffect } from "react";
import '../styles/certifications.css';

const Certif = ({data}) => (
    <div className="certificate_name">
        <h2>{data.name}</h2>
        <a href={data.link} target="_blank">View</a>
    </div>
)

const Certifications = () => {
    const [jsonData, setJsonData] = useState([]);

    useEffect(() => {
        fetch('./json/certificates.json').then(response => {
            return response.json();
          }).then(data => {
            setJsonData(data)
          }).catch((e) => {
            console.log(e.message);
          });
      },[])

    return (
        <>
            <h1 className="certificationsHeader">Certifications</h1>
            {
                jsonData.map((dataItem) => (
                    <Certif key={dataItem.no} data={dataItem} />
                ))
            }
        </>
    )
}

export default Certifications;